<template>
    <div class="p-4 flex">
        <!-- Inputs Pane -->
        <div class="w-1/2 mr-2">
            <form @submit.prevent="calculate" class="space-y-4">
                <div>
                    <label for="trucks" class="block text-sm font-medium text-gray-700">Number of trucks in fleet</label>
                    <input v-model="trucks" id="trucks"
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        type="number">
                </div>

                <div>
                    <label for="laborRate" class="block text-sm font-medium text-gray-700">Technician labor rate</label>
                    <input v-model="laborRate" id="laborRate"
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        type="number">
                </div>

                <div>
                    <label for="spares" class="block text-sm font-medium text-gray-700">Total spares</label>
                    <input v-model="spares" id="spares"
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        type="number">
                </div>

                <div>
                    <label for="towCosts" class="block text-sm font-medium text-gray-700">Tow costs</label>
                    <input v-model="towCosts" id="towCosts"
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        type="number">
                </div>

                <button type="submit"
                    class="w-full mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Calculate</button>
            </form>
        </div>

        <!-- Results Pane -->
        <div class="w-1/2 ml-2 space-y-8">
            <div class="p-4 border border-gray-200 rounded">
                <h2 class="text-xl mb-4">Engine Alert Cost Savings</h2>
                <div class="flex justify-between">
                    <div class="border-r pr-2">
                        <span>High vs. Low:</span>
                        <span>{{ results.engineAlert.highVsLow }}</span>
                    </div>
                    <div class="pl-2">
                        <span>Catastrophic vs. Low:</span>
                        <span>{{ results.engineAlert.catastrophicVsLow }}</span>
                    </div>
                </div>
            </div>

            <!-- ... repeat for each type of alert -->

            <div class="p-4 border border-gray-200 rounded">
                <h2 class="text-xl mb-4">Spare Cost Savings</h2>
                <div class="flex justify-between">
                    <div class="border-r pr-2">
                        <span>High vs. Low:</span>
                        <span>{{ results.spareCost.highVsLow }}</span>
                    </div>
                    <div class="pl-2">
                        <span>Catastrophic vs. Low:</span>
                        <span>{{ results.spareCost.catastrophicVsLow }}</span>
                    </div>
                </div>
            </div>

            <!-- ... and so on for each of the remaining outputs -->
        </div>
    </div>
</template>
  
<script setup>
import { ref, reactive } from 'vue'

const trucks = ref(0)
const laborRate = ref(0)
const spares = ref(0)
const towCosts = ref(0)

const results = reactive({
    engineAlert: { highVsLow: 0, catastrophicVsLow: 0 },
    coolantAlert: { highVsLow: 0, catastrophicVsLow: 0 },
    exhaustAlert: { highVsLow: 0, catastrophicVsLow: 0 },
    brakeAlert: { highVsLow: 0, catastrophicVsLow: 0 },
    spareCost: { highVsLow: 0, catastrophicVsLow: 0 },
    spareRatio: { highVsLow: 0, catastrophicVsLow: 0 },
    numberOfSpares: { highVsLow: 0, catastrophicVsLow: 0 },
    netCostSavings: { highVsLow: 0, catastrophicVsLow: 0 },
    paybackPeriod: { highVsLow: 0, catastrophicVsLow: 0 },
    // TODO: Add rest of results here
})

function calculate() {
    // TODO: Perform calculations and update `results` object
    results.engineAlert.highVsLow = trucks.value * laborRate.value // example calculation
    // ...
}
</script>
  