<template>
  <form>
    <div class="pl-2 py-2">
      <div class="grid grid-cols-4 gap-4 bg-gray-50">
        <div>
          <label for="locale" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Locale</label>
          <div class="mt-1 sm:col-span-2 sm:mt-0">
            <select v-model="signatureLocale" id="locale" name="locale"
              class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm">
              <option value="en" selected>English</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ag-grid-vue class="ag-theme-alpine flex-1 flex flex-col" :columnDefs="columnDefs" :rowData="rowData"
    :defaultColDef="defaultColDef" :detailRowAutoHeight="true" :animateRows="true" :rowGroupPanelShow="true" :sideBar="true" :masterDetail="true" :detailCellRendererParams="detailCellRendererParams"
    @grid-ready="onGridReady">
  </ag-grid-vue>
</template>

<script>

import { watch, computed, reactive, onMounted, ref } from "vue";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

export default {
  name: "App",
  components: {
    AgGridVue,
  },
  setup() {
    const gridApi = ref(null); // Optional - for accessing Grid's API
    const gridColumnApi = ref(null);
    const rowData = ref(null);
    const signatureLocale = ref("en");

    // Obtain API from grid's onGridReady event
    const onGridReady = (params) => {
      gridApi.value = params.api;
      gridColumnApi.value = params.columnApi;
    };

    // DefaultColDef sets props common to all Columns
    const defaultColDef = {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
      resizable: true
    };    

    const columnDefs = [
        { 
            field: "name_translations",
            valueFormatter: (data) => {
                if (data.value)
                    return data.value[signatureLocale.value];
                else
                    return "";
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
            cellClass: "py-2",            
            cellRenderer: 'agGroupCellRenderer'
        },
        { 
          field: "client_id" 
        },
        { field: "id", hide: true },
        { 
          field: "signature_json",
          headerName: "Code",
          filterParams: {
            valueFormatter: (data) => {
              if (Array.isArray(data.value.codes))
                return data.value.codes.map(c => c.code).join(", ");
              else
                return "No Code";
            },
            keyCreator: (data) => {
              if (Array.isArray(data.value.codes))
                return data.value.codes.map(c => c.code).join("-");
              else
                return "no-code";
            },
          },
          valueFormatter: (data) => {
            if (Array.isArray(data.value.codes))
              return data.value.codes.map(c => c.code).join(", ");
            else
              return "No Code";
          },
          // keyCreator: (data) => {
          //   return data.value.codes.map(c => c.code).join("-");
          // },
        },
        { field: "signature_number" },
        { field: "source_type" },
        { field: "base_signature" },
        { field: "base_signature_id" },
        { field: "group_override" },
        { field: "vehicle_override" },
        { field: "created_at", hide: true },
        { field: "updated_at", hide: true },
    ];

    const detailCellRendererParams = {
        // provide the Grid Options to use on the Detail Grid
        detailGridOptions: {
            columnDefs: [
              { field: "affected_system" },
              { field: "low_count"},
              { field: "medium_count"},
              { field: "high_count"},
              { 
                  field: "corrective_procedure_translations",
                  valueFormatter: (data) => {
                      return data.value[signatureLocale.value];
                  },
                  wrapText: true,
                  autoHeight: true,
                  cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
                  cellClass: "py-2",                    
              },
              { 
                  field: "emerging_problem_translations",
                  valueFormatter: (data) => {
                      if (data.value)
                          return data.value[signatureLocale.value];
                      else
                          return "";
                  },
                  wrapText: true,
                  autoHeight: true,
                  cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
                  cellClass: "py-2",
              },
              { 
                  field: "low_current_condition_translations",
                  valueFormatter: (data) => {
                      if (data.value)
                          return data.value[signatureLocale.value];
                      else
                          return "";
                  },
                  wrapText: true,                    
                  autoHeight: true,
                  cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
                  cellClass: "py-2",
              },
              { 
                  field: "medium_current_condition_translations",
                  valueFormatter: (data) => {
                      if (data.value)
                          return data.value[signatureLocale.value];
                      else
                          return "";
                  },
                  wrapText: true,                    
                  autoHeight: true,
                  cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
                  cellClass: "py-2",
              },
              { 
                  field: "high_current_condition_translations",
                  valueFormatter: (data) => {
                      if (data.value)
                          return data.value[signatureLocale.value];
                      else
                          return "";
                  },
                  wrapText: true,                    
                  autoHeight: true,
                  cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
                  cellClass: "py-2",
              },
              { field: "spn_fmi_tuple"},
              { field: "created_at", hide: true },
              { field: "updated_at", hide: true },
            ],
            defaultColDef: defaultColDef        
        },
        // get the rows for each Detail Grid
        getDetailRowData: params => {
            fetch(import.meta.env.VITE_WEB_SERVER_HOST + "/signature_lookups.json?q[signature_id_eq]=" + params.data.id)
                .then((result) => result.json())
                .then((remoteRowData) => (params.successCallback(remoteRowData)));  
        },
    };

    // Example load data from sever
    onMounted(() => {
        fetch(import.meta.env.VITE_WEB_SERVER_HOST + "/.json")
            .then((result) => result.json())
            .then((remoteRowData) => (rowData.value = remoteRowData));    
    });
    
    return {
        onGridReady,
        columnDefs,
        rowData,
        defaultColDef,
        detailCellRendererParams,
        signatureLocale
    }

  },
};
</script>