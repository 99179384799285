// src/utils/vinValidator.js

class VINValidator {
    static VIN_LENGTH = 17;
    static INVALID_CHARACTERS = ['I', 'O', 'Q'];
    static WEIGHTS = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
    static CHAR_VALUES = {
      'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'F': 6, 'G': 7, 'H': 8, 'J': 1, 'K': 2, 'L': 3, 'M': 4, 'N': 5,
      'P': 7, 'R': 9, 'S': 2, 'T': 3, 'U': 4, 'V': 5, 'W': 6, 'X': 7, 'Y': 8, 'Z': 9,
      '0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9
    };
  
    static validate(vin) {
      let errors = [];
  
      // Check for leading and trailing whitespace
      if (/^\s+/.test(vin)) {
        errors.push("VIN has leading whitespace.");
      }
      if (/\s+$/.test(vin)) {
        errors.push("VIN has trailing whitespace.");
      }
  
      let trimmedVin = vin.trim().toUpperCase();
  
      if (trimmedVin.length !== VINValidator.VIN_LENGTH) {
        errors.push(`VIN must be exactly ${VINValidator.VIN_LENGTH} characters long.`);
      }
  
      if ([...trimmedVin].some(char => VINValidator.INVALID_CHARACTERS.includes(char))) {
        errors.push("VIN cannot contain the characters I, O, or Q.");
      }
  
      if (errors.length === 0 && !VINValidator.validCheckDigit(trimmedVin)) {
        errors.push("VIN check digit is invalid (9th character).");
      }
  
      return errors.length === 0 ? "None" : errors.join(" ");
    }
  
    static validCheckDigit(vin) {
      let checkDigit = vin[8];
      let calculatedCheckDigit = VINValidator.calculateCheckDigit(vin);
  
      return checkDigit === calculatedCheckDigit || (checkDigit === 'X' && calculatedCheckDigit === '10');
    }
  
    static calculateCheckDigit(vin) {
      let sum = [...vin].reduce((acc, char, index) => {
        return acc + (VINValidator.CHAR_VALUES[char] * VINValidator.WEIGHTS[index]);
      }, 0);
  
      let remainder = sum % 11;
      return remainder === 10 ? 'X' : remainder.toString();
    }
  }
  
  export default VINValidator;
  