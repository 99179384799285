import { defineStore } from 'pinia'
import { ref } from 'vue'

// ref()s become state properties
// computed()s become getters
// function()s become actions

export const useReportingStore = defineStore('reporting', () => {
    const datasets = ref([
        { value: 'incidents', label: 'Current Incidents' },
        { value: 'past_incidents', label: 'Past Incidents' },
        { value: 'vehicles', label: 'Vehicles' },
        { value: 'dismissal_rules', label: 'Dismissal Rules' },
        // { value: 'user_actions', label: 'Users' },
        // { value: 'vehicle_snapshots', label: "Weekly Vehicle Reporting" },
    ])

    const gridApi = ref(null); // Optional - for accessing Grid's API
    const gridColumnApi = ref(null);

    const saveDialogOpen = ref(false);
    const advancedMode = ref(false);
    const spareEditMode = ref(false);

    // function increment() {
    //   count.value++
    // }
  
    return { datasets, gridApi, gridColumnApi, advancedMode, spareEditMode, saveDialogOpen }
  })