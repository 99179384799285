<template>
    <div class="bg-gray-100">
        <div class="mx-auto px-4">
            <div class="flex flex-col">
                <div class="flex space-x-4 mt-4">
                    <button v-for="(button, index) in buttons" :key="index" :class="{
                        'bg-blue-500 text-white': selectedButtonIndex === index,
                        'bg-gray-200 text-gray-600': selectedButtonIndex !== index,
                    }"
                        class="px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 hover:text-white focus:bg-blue-600 focus:text-white focus:outline-none transition-colors duration-200"
                        @click="setDuration(button.duration, index)">
                        {{ button.text }}
                    </button>
                    <div v-if="state.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                        role="alert">
                        <strong class="font-bold">Error: </strong>
                        <span class="block sm:inline">{{ state.error }}</span>
                    </div>
                    <div v-if="state.loading" class="flex items-center justify-center">
                        <svg class="animate-spin h-5 w-5 text-gray-600 mr-3" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                        <span>Loading...</span>
                    </div>
                </div>
                <div class="flex flex-row mt-4 space-x-4 w-full">
                    <div class="flex-1">
                        <div class="border border-gray-300 rounded-lg shadow-md h-96" ref="chartContainer" style="height: 500px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, reactive, watch, onMounted, onUnmounted, toRaw } from 'vue';
import * as echarts from 'echarts';
import { DateTime } from 'luxon';

export default {
    setup() {
        const urlParams = new URLSearchParams(window.location.search);
        const startTime = ref(DateTime.now().minus({ hours: 168 }));
        const endTime = ref(DateTime.now());
        const units = ref('mi');
        const sfAssetId = urlParams.get('sf_asset_id') || 'a066Q00000WoOktQAF';
        const rentalStartTime = urlParams.get('rental_start_time');
        const chartContainer = ref(null);
        let chartInstance = null;
        const chartData = ref([]);
        const selectedButtonIndex = ref(0);
        const buttons = ref([
            { text: 'Week', duration: 168 },
            { text: 'Month', duration: 720 },
            { text: 'Year', duration: 8760 },
        ]);

        // Add a button with duration to buttons based on the rentalStartTime
        if (rentalStartTime) {
            buttons.value.push({
                text: 'Since Rental',
                duration: DateTime.now().diff(DateTime.fromISO(rentalStartTime), 'hours').hours,
            });
        }

        const state = reactive({
            sfAssetId,
            loading: false,
            error: null,
        });

        function setDuration(hours, index) {
            selectedButtonIndex.value = index;

            startTime.value = DateTime.now().minus({ hours: hours });
            endTime.value = DateTime.now();
        }

        function setDurationFromURL() {
            const urlParams = new URLSearchParams(window.location.search);
            const startParam = urlParams.get('startTime');
            const endParam = urlParams.get('endTime');

            if (startParam && endParam) {
                const start = new Date(startParam);
                const end = new Date(endParam);

                if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
                    startTime.value = start;
                    endTime.value = end;
                    return true;
                }
            }
            return false;
        }

        const updateChart = () => {
            // Update and merge
            chartInstance.setOption({
                dataset: [
                    {
                        source: chartData.value,
                        dimensions: [
                            { name: 'event_ts', type: 'time' },
                            { name: 'data', type: 'float' },
                            { name: 'code', type: 'ordinal' },
                            { name: 'external_vehicle_id', type: 'ordinal' },
                        ],
                    },
                    {
                        transform: [
                            {
                                type: 'filter',
                                config: {
                                    dimension: 'code',
                                    value: 'DiagnosticEngineHoursId',
                                },
                            },
                            {
                                type: 'sort',
                                config: {
                                    dimension: 'event_ts',
                                    order: 'desc',
                                },
                            },
                        ]

                    },
                    {
                        transform: [
                            {
                                type: 'filter',
                                config: {
                                    dimension: 'code',
                                    value: 'DiagnosticOdometerId',
                                },
                            },
                            {
                                type: 'sort',
                                config: {
                                    dimension: 'event_ts',
                                    order: 'desc',
                                },
                            },
                        ]

                    },
                ],
            });
        };

        onMounted(async () => {

            chartInstance = echarts.init(chartContainer.value, null, { height: 500 });

            initChart();

            fetchData();

            window.addEventListener('resize', resizeChart);

            if (!setDurationFromURL()) {
                setDuration(24, 0);
            }

        });

        onUnmounted(() => {
            if (chartInstance) {
                chartInstance.dispose();
            }
            window.removeEventListener('resize', resizeChart);
        });

        const resizeChart = () => {
            chartInstance.resize();
        };

        const initChart = () => {
            chartInstance.setOption({
                dataset: [
                    {
                        source: chartData,
                        dimensions: [
                            { name: 'event_ts', type: 'time' },
                            { name: 'data', type: 'float' },
                            { name: 'code', type: 'ordinal' },
                            { name: 'external_vehicle_id', type: 'ordinal' },
                        ],
                    },
                    {
                        transform: [
                            {
                                type: 'filter',
                                config: {
                                    dimension: 'code',
                                    value: 'DiagnosticEngineHoursId',
                                },
                            },
                            {
                                type: 'sort',
                                config: {
                                    dimension: 'event_ts',
                                    order: 'desc',
                                },
                            },
                        ]

                    },
                    {
                        transform: [
                            {
                                type: 'filter',
                                config: {
                                    dimension: 'code',
                                    value: 'DiagnosticOdometerId',
                                },
                            },
                            {
                                type: 'sort',
                                config: {
                                    dimension: 'event_ts',
                                    order: 'desc',
                                },
                            },
                        ]

                    },
                ],
                grid: [
                {
                    bottom: '60%',
                },
                {
                    top: '60%',
                },
                ],
                title: [
                    {
                        text: 'Engine Hours',
                        left: '50%',
                        textAlign: 'center',
                        top: 10,
                    },
                    {
                        text: 'Odometer',
                        top: '50%',
                        left: '50%',
                        textAlign: 'center',
                    },
                ],
                xAxis: [
                    // format the time axis label
                    {
                        type: 'time',
                        gridIndex: 0,
                        axisLabel: {
                            showMinLabel: true,
                            showMaxLabel: true,
                        }
                        // axisLabel: {
                        //     formatter: {
                        //         year: '{yyyy}',
                        //         month: '{MMM}',
                        //         day: '{d}',
                        //         hour: '{HH}:{mm}',                                
                        //     }
                        // },
                    },
                    {
                        type: 'time',
                        gridIndex: 1,
                        axisLabel: {
                            showMinLabel: true,
                            showMaxLabel: true,
                        }
                        // axisLabel: {
                        //     formatter: {
                        //         year: '{yyyy}',
                        //         month: '{MMM}',
                        //         day: '{d}',
                        //         hour: '{HH}:{mm}',                                
                        //     }
                        // },
                    }
                ],
                yAxis: [
                    { type: 'value', gridIndex: 0, scale: true, name: 'Hours', nameLocation: 'middle', nameGap: 50 },
                    { type: 'value', gridIndex: 1, scale: true, name: 'Miles', nameLocation: 'middle', nameGap: 50 },
                ],
                series: [
                    {
                        name: "Engine Hours",
                        type: 'line',
                        datasetIndex: 1,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        connectNulls: true,                        
                    },
                    {
                        name: "Odometer",
                        type: 'line',
                        datasetIndex: 2,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        connectNulls: true,
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                    },
                },
                toolbox: {
                    right: 10,
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        saveAsImage: {}
                    }
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: [0,1],
                    }, {
                        type: 'inside',
                        xAxisIndex: [0, 1],
                    },
                ],
            });
        };

        const fetchData = async () => {
            state.loading = true;
            state.error = null;

            try {
                const response = await fetch(
                    `/status_datum?sf_asset_id=${state.sfAssetId}&start_time=${startTime.value}&end_time=${endTime.value}`
                );

                if (response.ok) {

                    const data = await response.json();

                    // Convert raw data to specified the units variable based on code
                    chartData.value = data.map((row) => {
                        if (row.code === 'DiagnosticOdometerId') {
                            if (units === 'mi') {
                                row.data = (row.data * 0.000621371).toFixed(2); // mi
                            } else {
                                row.data = (row.data * 0.001).toFixed(2); // km
                            }
                        } else if (row.code === 'DiagnosticEngineHoursId') {
                            row.data = row.data / 3600; // seconds to hours
                        }
                        return row;
                    });
                } else {
                    state.error = await response.text();
                }

            } catch (error) {
                state.error = error.message;
                console.error(error);
            } finally {
                state.loading = false;
            }
        }

        // If the start or end time changes, fetch the data
        watch(selectedButtonIndex, fetchData);

        watch(chartData, async (newChartData, oldChartData) => {
            updateChart();
        });

        return { chartContainer, state, startTime, endTime, setDuration, buttons, selectedButtonIndex, units };
    }
}        
</script>

<style scoped>/* Add any necessary styles here */</style>
