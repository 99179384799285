<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <TransitionRoot as="template" :show="saveDialogOpen">
    <Dialog as="div" class="relative z-[9999]">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-[9999] overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ClipboardDocumentCheckIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:flex-grow">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Save Report</DialogTitle>
                    <div class="mt-2">
                      <div>
                        <label for="reportName" class="sr-only">Report Name</label>
                        <input name="reportName" type="text" v-model="reportName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" placeholder="Enter report name" required />
                      </div>
                      <div class="error text-red-500 mt-2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" @click="saveReport">
                  Save
                </button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="onSaveDialogCancelClick">
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <form>
    <div class="pl-2 py-2">
      <div class="grid grid-cols-4 gap-4 bg-gray-50">
        <div>
          <label for="datasets" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Dataset</label>
          <div class="mt-1 sm:col-span-2 sm:mt-0">
            <select v-model="dataset" id="datasets" name="datasets"
              class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm">
              <option 
                v-for="(item, index) in datasets" 
                :key="index" 
                :value="item.value" 
                :selected="item.value === dataset">
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <label for="reports" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Report</label>
          <div class="mt-1 sm:col-span-2 sm:mt-0">
            <select v-model="report" id="reports" name="reports"
              class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm">
              <option v-for="report in currentSavedReports" :value="report.value">
                {{ report.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="pt-7">
          <button @click="onResetClick" type="button"
            class="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            <ArrowPathRoundedSquareIconMini class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            Reset
          </button>
          <!-- <button @click="onSaveClick" type="button"
            class="inline-flex ml-2 items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            <ArrowDownOnSquareIconMini class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            Save
          </button> -->
        </div>
        <div class="pt-7">
        </div>
      </div>
    </div>
  </form>
  <ag-grid-vue class="ag-theme-quartz flex-1 flex flex-col" :rowData="rowData"
    groupDisplayType="groupRows" :defaultColDef="defaultColDef" :dataTypeDefinitions="dataTypeDefinitions" :statusBar="statusBar" :groupRemoveSingleChildren="true" :groupDefaultExpanded="groupDefaultExpanded"
    :autoGroupColumnDef="autoGroupColumnDef" :rowGroupPanelShow="rowGroupPanelShow" :sideBar="advancedMode" :functionsReadOnly="!advancedMode"
    :animateRows="true" @state-updated="onGridStateUpdated" @grid-ready="onGridReady" @cellValueChanged="onCellValueChanged" :defaultExcelExportParams="defaultExcelExportParams" :excelStyles="excelStyles">
  </ag-grid-vue>
</template>

<script>

import { DateTime, Duration } from 'luxon';
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import { watch, computed, reactive, onBeforeMount, onMounted, ref } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import { ArrowPathRoundedSquareIcon as ArrowPathRoundedSquareIconMini, ArrowDownOnSquareIcon as ArrowDownOnSquareIconMini, ChevronDoubleUpIcon as ChevronDoubleUpIconMini, ChevronDoubleDownIcon as ChevronDoubleDownIconMini } from '@heroicons/vue/20/solid';
import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline'

import VINValidator from '../../utils/vinValidator.js';
import { useReportingStore } from '../../stores/reporting.js';
import { storeToRefs } from 'pinia'

export default {
  name: "App",
  components: {
    AgGridVue,
    ChevronDoubleUpIconMini,
    ChevronDoubleDownIconMini,
    ArrowDownOnSquareIconMini,
    ArrowPathRoundedSquareIconMini,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ClipboardDocumentCheckIcon,
  },
  setup() {

    const affected_systems_mapping = { "013":"Brakes", "031":"Charging", "042":"Coolant", "045":"Engine", "043":"Exhaust", "044":"Fuel", "001":"Climate", "002":"Body", "027":"Transmission", "800":"3rd Eye", "801":"Greasing" };

    const store = useReportingStore()

    const groupDefaultExpanded = ref(0);

    const { datasets, gridApi, gridColumnApi, advancedMode, spareEditMode, saveDialogOpen } = storeToRefs(store)

    // Obtain API from grid's onGridReady event
    const onGridReady = (params) => {
      gridApi.value = params.api;
      gridColumnApi.value = params.columnApi;
    }

    const onGridStateUpdated = (event) => {
      console.log('State updated', event.state);
    }

    const onCellValueChanged = event => {
      if (dataset.value === "dismissal_rules" && event.column.colId === "active") {
        // We need to pass event.value to the Rails dismissal_rules controller
        fetch(import.meta.env.VITE_WEB_SERVER_HOST + `/dismissal_rules/${event.data.id}.json`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            // "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
          },
          body: JSON.stringify({ "dismissal_rule": { active: event.value } })
        })
          .then(response => response.json())
          .then(data => {
            console.log(data);
          });
      } else if (dataset.value === "vehicles" && event.column.colId === "spare") {
        // We need to pass event.value to the Rails dismissal_rules controller
        fetch(import.meta.env.VITE_WEB_SERVER_HOST + `/vehicles/${event.data.id}.json`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            // "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
          },
          body: JSON.stringify({ "vehicle": { spare: event.value } })
        })
          .then(response => response.json())
          .then(data => {
            console.log(data);
          });
      }
    };

    const onSaveClick = () => {

      const selectedReport = currentSavedReports.value.find(r => r.value === report.value);
      if (selectedReport) {
        reportName.value = selectedReport.name;
      }
      
      console.log(gridColumnApi.value.getColumnState());
      console.log(gridApi.value.getFilterModel());
      saveDialogOpen.value = true;
    };

    const onSaveDialogCancelClick = () => {
      saveDialogOpen.value = false;
    };

    const onResetClick = () => {
      gridApi.value.setFilterModel(null);
      gridApi.value.setRowGroupColumns([]);
      gridApi.value.updateGridOptions({ pivotMode: false });
      gridApi.value.updateGridOptions({ columnDefs: columnDefs.value[dataset.value] });
      console.log("Setting column defs to ", dataset.value);
      report.value = null;
      gridApi.value.onFilterChanged();
    };

    const restoreState = () => {
      if (!window.colState) {
        console.log(
          'no columns state to restore by, you must save state first'
        );
        return;
      }
      gridApi.applyColumnState({
        state: window.colState,
        applyOrder: true,
      });
      console.log('column state restored');
    }

    const savedReports = ref([]);
    const currentSavedReports = computed(() => {
      var reportsToShow = savedReports.value.filter(sr => sr.dataset == dataset.value).sort((a, b) => a.name.localeCompare(b.name));

      // Add a placeholder for the "New Report" option
      // <option disabled value="">Select a report</option>
      reportsToShow.unshift({ value: null, name: "Select a report" });
      
      return savedReports.value.filter(sr => sr.dataset == dataset.value).sort((a, b) => a.name.localeCompare(b.name));
    });
    
    const dataTypeDefinitions = ref(null);
    const rowData = ref(null);

    const excelStyles = [
      {
          id: 'stringType',
          dataType: 'String',
      },
    ]

    const filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateA = DateTime.fromJSDate(filterLocalDateAtMidnight);
        const dateB = DateTime.fromISO(cellValue);

        if (dateB < dateA) {
          return -1;
        } else if (dateB > dateA) {
          return 1;
        } else {
          return 0;
        }
      },
      browserDatePicker: true,
      minValidYear: 2000,
      maxValidYear: new Date().getFullYear(),
      inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    };

    const columnDefs = ref({
      "incidents": [
        {
          field: "region",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "division",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "groups",
          headerName: "Groups",
          filter: 'agSetColumnFilter',
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        { field: "id", hide: true },
        { field: "severity" },
        {
          field: "incident_time_stamp",
          sort: 'desc',
          headerName: "Timestamp",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "vehicle_name",
          headerName: "Vehicle"
        },
        { field: "vin", hide: true },
        { field: "system_type", headerName: "System Type", hide: true },
        { field: "make" },
        { field: "model", cellClass: 'stringType' },
        { field: "engine_model", headerName: "Engine Model", hide: true },
        { field: "year" },
        {
          field: "affected_system",
          headerName: "System",
        },
        {
          field: "emerging_problem",
          headerName: "Emerging Problem",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "corrective_procedure",
          headerName: "Corrective Procedure",
          hide: true,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "current_condition",
          headerName: "Current Condition",
          hide: true,
        },
        { field: "protocol", hide: true },
        { field: "status" },
      ],
      "past_incidents": [
        {
          field: "groups",
          headerName: "Groups",
          filter: 'agSetColumnFilter',
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "low_its",
          headerName: "Low Timestamp",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "medium_its",
          headerName: "Medium Timestamp",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "high_its",
          headerName: "High Timestamp",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "latest_its",
          headerName: "Last Timestamp",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "pm_its",
          headerName: "PM Timestamp",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "maint_its",
          headerName: "MAINT Timestamp",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "complete_its",
          headerName: "Complete Timestamp",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "vehicle_name",
          headerName: "Vehicle",
          onCellDoubleClicked: (params) => {
            integrationState.value['geotab'].state.gotoPage("addin-optimum_fleet_health-timeline", { vehicle_id: params.data.vehicle_id, referer: "live_report" });
          }
        },
        { field: "vin", hide: true },
        { field: "system_type", headerName: "System Type", hide: true },
        { field: "make" },
        { field: "model", cellClass: 'stringType' },
        { field: "engine_model", headerName: "Engine Model", hide: true },
        { field: "year" },
        {
          field: "affected_system",
          headerName: "System",
          valueFormatter: (data) => {
            return affected_systems_mapping[data.value];
          }
        },
        {
          field: "emerging_problem",
          headerName: "Emerging Problem",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        { 
          field: "chain_incident_id", 
          headerName: "Incident Chain ID", 
          hide: true 
        },
      ],
      "vehicles": [
        {
          field: "region",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "division",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "groups",
          headerName: "Groups",
          filter: 'agSetColumnFilter',
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "vin",
        },
        {
          hide: true,
          headerName: "Scrap",
          field: "scrap_vin",
          cellDataType: "boolean",
        },       
        {
          headerName: "VIN Error",
          colId: "vin_error",
          cellDataType: "string",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",          
          valueGetter: (params) => {
            return params.data && VINValidator.validate(params.data.vin);
          }
        },
        {
          field: "system_type",
          headerName: "System Type",
          hide: true,
        },
        {
          field: "is_rta_uninstalled",
          headerName: "RTA Uninstalled",
          hide: true,
        },
        {
          field: "is_local_orphaned",
          headerName: "Local Orphaned",
          hide: true,
        },
        {
          field: "id",
          hide: true,
        },
        {
          field: "vehicle_name",
          headerName: "Vehicle",
          // filter: 'agMultiColumnFilter',
          // filterParams: {
          //   filters: [
          //     { filter: 'agTextColumnFilter', filterParams: { readOnly: true } },
          //     { filter: 'agSetColumnFilter', },
          //   ],
          // },
        },
        {
          field: "pre_install_serial",
          headerName: "Pre-Install Serial",
          hide: true,
        },
        { field: "spare", 
          headerName: "Spare Geotab",
          hide: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          editable: true, // spareEditMode,
          // editable: (params) => {
          //   console.log((params.data.last_position_update || params.data.last_position_update.trim() != ''))
          //   return (params.data.last_position_update || params.data.last_position_update.trim() != '') && spareEditMode
          // }
        },
        { 
          field: "replaced_geotab_identifier", 
          headerName: "Previous Geotab ID",
          hide: true,
        },      
        { 
          field: "replaced_geotab_last_contact", 
          headerName: "Previous Geotab Last Contact",
          hide: true,
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },      
        { 
          field: "geotab_serial_number", 
          headerName: "Geotab Serial Number",
          hide: true,
        },      
        {
          field: "make",
        },
        { field: "model", cellClass: 'stringType' },
        {
          field: "engine_model",
          headerName: "Engine Model",
          hide: true,
        },
        {
          headerName: "Year",
          field: "year",
          filter: 'agNumberColumnFilter',
          valueGetter: (params) => {
              if (!params.data || typeof params.data.year === 'undefined') {
                  return 9999;  // Default to 9999 if params.data or year is undefined
              }
              
              const yearValue = params.data.year;
              if (yearValue === '' || yearValue == null) {
                  return 9999;  // Convert blanks to 9999
              }
              
              return parseInt(yearValue, 10) || 9999;  // Convert string to number, default to 9999 if parsing fails
          },
          valueFormatter: (params) => {
            return params.value === 9999 ? 'Unknown Year' : params.value;  // Display "Unknown Year" for 9999
          },
          // filterParams: {
          //   filterOptions: ['greaterThan'],
          //   defaultOption: 'greaterThan',
          //   suppressAndOrCondition: true,
          //   debounceMs: 200,
          //   minValidYear: 2000,
          //   comparator: (filterValue, cellValue) => {
          //     return cellValue >= 2000;  // Only include years greater than 2000 in the filter
          //   }
          // }
        },
        {
          field: "latitude",
          hide: true,
        },
        {
          field: "longitude",
          hide: true,
        },
        {
          field: "last_position_update",
          headerName: "Last Position Update",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          headerName: "Last Contact",
          colId: "last_contact",
          valueGetter: (params) => {
            if (typeof params.data !== 'undefined') {
              var formatted_datetime = DateTime.fromISO(params.data.last_position_update);
              if (formatted_datetime.isValid)
                return DateTime.now().diff(formatted_datetime, "days").toObject()['days'] < 1 ? 0 : DateTime.now().diff(formatted_datetime, "days").toObject()['days'].toFixed(0);
              else
                return null;
            } else {
              return null;
            }
          },
          comparator: (a, b) => (a - b),
          valueFormatter: (params) => {
            if (params.value == null)
              return null;
            else if (params.value == 0)
              return "Today";
            else if (params.value == 1)
              return params.value + " day";
            else
              return params.value + " days";
          },
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: "In RTA",
          colId: "in_rta",
          cellDataType: "boolean",
          valueGetter: (params) => {
            return params.data && params.data.system_type !== "Unknown System Type";
          }
        },
        {
          headerName: "Is Trailer",
          colId: "is_trailer",
          cellDataType: "boolean",
          valueGetter: (params) => {
            return params.data && params.data.is_trailer;
          }
        },
        {
          headerName: "Vehicle On Order",
          colId: "on_order",
          cellDataType: "boolean",
          valueGetter: (params) => {
            return params.data && /\d{4}-\d{2}-\d{4}/.test(params.data.vin);
          }
        },
        {
          headerName: "Vehicle In Production",
          colId: "in_production",
          cellDataType: "boolean",
          valueGetter: (params) => {
            return params.data && /ID#(\d+)-(\d+)/.test(params.data.vehicle_name);
          }
        },
        {
          field: "external_system_type",
          headerName: "External System Type",
          cellDataType: "text",
          filter: 'agSetColumnFilter',
          filterParams: {
            values: ['rta', 'geotab', '3rdeye']
          },
          valueFormatter: (params) => {
            if (!params.value)
              return "";

            if (params.value == "rta") {
              return "RTA";
            } else if (params.value == "geotab") {
              return "Geotab";
            } else if (params.value == "3rdeye") {
              return "3rdEye";
            } else {
              return params.value;
            }
          },
        },
        {
          headerName: "Odometer",
          field: "odometer",
          valueFormatter: (params) => {
            if (params.value) {
              return params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }) + " mi";
            } else {
              return null;
            }
          },
        },
        {
          headerName: "Engine Hours",
          field: "engine_hours",
          valueFormatter: (params) => {
            if (params.value) {
              return params.value.toLocaleString(undefined, { maximumFractionDigits: 0 });
            } else {
              return null;
            }
          },
        },
      ],
      "dismissal_rules": [
        {
          field: "active",
          maxWidth: 100,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          editable: true,
        },
        {
          field: "region",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
          hide: true,
        },
        {
          field: "division",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
          hide: true,
        },
        {
          field: "groups",
          headerName: "Groups",
          filter: 'agSetColumnFilter',
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "vin",
        },
        {
          field: "system_type",
          headerName: "System Type",
          hide: true,
        },
        {
          field: "id",
          hide: true,
        },
        {
          field: "vehicle_name",
          headerName: "Vehicle",
          maxWidth: 120,
        },
        {
          field: "make",
          hide: true,
        },
        {
          field: "model",
          cellClass: 'stringType',
          hide: true,
        },
        {
          field: "engine_model",
          headerName: "Engine Model",
          hide: true,
        },
        {
          field: "year",
          hide: true,
        },
        {
          field: "latitude",
          hide: true,
        },
        {
          field: "longitude",
          hide: true,
        },
        {
          field: "last_position_update",
          headerName: "Last Position Update",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
          hide: true,
        },
        {
          headerName: "Last Contact",
          valueGetter: (params) => {
            if (typeof params.data !== 'undefined') {
              var formatted_datetime = DateTime.fromISO(params.data.last_position_update);
              if (formatted_datetime.isValid)
                return DateTime.now().diff(formatted_datetime, "days").toObject()['days'] < 1 ? 0 : DateTime.now().diff(formatted_datetime, "days").toObject()['days'].toFixed(0);
              else
                return null;
            } else {
              return null;
            }
          },
          comparator: (a, b) => (a - b),
          valueFormatter: (params) => {
            if (params.value == null)
              return null;
            else if (params.value == 0)
              return "Today";
            else if (params.value == 1)
              return params.value + " day";
            else
              return params.value + " days";
          },
          filter: 'agNumberColumnFilter',
          hide: true,
        },
        {
          field: "external_system_type",
          headerName: "External System",
          hide: true,
        },
        {
          field: "affected_system",
          headerName: "Affected System",
          maxWidth: 110,
        },
        {
          field: "emerging_problem",
          headerName: "Emerging Problem",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
          flex: 1,
        },
        {
          field: "dismissal_type",
          headerName: "Dismissal Type",
          maxWidth: 110,
          valueFormatter: (params) => {
            if (!params.value)
              return null;
            let words = params.value.split('_');
            let titleCaseWords = words.map(word => {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            });
            return titleCaseWords.join(' ');
          },
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "dismiss_until",
          headerName: "Dismiss Until",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
          maxWidth: 110,
        },
        {
          field: "duration",
          headerName: "Dismissal Duration",
          maxWidth: 110,
          valueFormatter: (params) => {
            if (params.value) {
              const durationObject = Duration.fromObject({ minutes: params.value }).shiftTo('years', 'months', 'weeks', 'days', 'hours', 'minutes').toObject();

              // filter out units with 0 values.
              const resultObject = Object.keys(durationObject).reduce((result, key) => {
                const value = durationObject[key];
                return value ? { ...result, [key]: value } : result;
              }, {});

              return Duration.fromObject(resultObject).toHuman();
            } else
              return null;
          },
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "reason",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
          maxWidth: 150,
        },
        {
          field: "created_at",
          headerName: "Created At",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
          maxWidth: 100,
        },

      ],
      "user_actions": [
        {
          field: "id",
          headerName: "User ID",
        },
        {
          field: "name",
          headerName: "Username",
          hide: true,
        },
        {
          field: "regions",
          headerName: "Regions",
          filter: 'agSetColumnFilter',
        },
        {
          field: "divisions",
          headerName: "Divisions",
          filter: 'agSetColumnFilter',
        },
        {
          field: "groups",
          headerName: "Groups",
          filter: 'agSetColumnFilter',
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "first_name",
          headerName: "First Name",
        },
        {
          field: "last_name",
          headerName: "Last Name",
        },
        {
          field: "designation",
          headerName: "Role",
        },
        {
          field: "last_access_date",
          headerName: "Last Access Date",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
          sort: 'desc',
        },
        {
          headerName: "Days Since Last Action",
          colId: "last_action_days",
          valueGetter: (params) => {
            if (typeof params.data !== 'undefined') {
              var formatted_datetime = DateTime.fromISO(params.data.last_access_date);
              if (formatted_datetime.isValid)
                return DateTime.now().diff(formatted_datetime, "days").toObject()['days'] < 1 ? 0 : DateTime.now().diff(formatted_datetime, "days").toObject()['days'].toFixed(0);
              else
                return null;
            } else {
              return null;
            }
          },
          comparator: (a, b) => (a - b),
          valueFormatter: (params) => {
            if (params.value == null)
              return null;
            else if (params.value == 0)
              return "Today";
            else if (params.value == 1)
              return params.value + " day";
            else
              return params.value + " days";
          },
          filter: 'agNumberColumnFilter',
        },      
      ],
      "vehicle_snapshots": [
        {
          field: "region",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "division",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "groups",
          headerName: "Groups",
          filter: 'agSetColumnFilter',
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          field: "vin",
        },
        {
          field: "system_type",
          headerName: "System Type",
          hide: true,
        },
        {
          field: "id",
          hide: true,
        },
        {
          field: "vehicle_name",
          headerName: "Vehicle",
        },
        {
          field: "pre_install_serial",
          headerName: "Pre-Install Serial",
          hide: true,
        },
        { field: "spare", 
          headerName: "Spare Geotab",
          hide: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          editable: true, //spareEditMode,
          // editable: (params) => {
          //   console.log((params.data.last_position_update || params.data.last_position_update.trim() != ''))
          //   return (params.data.last_position_update || params.data.last_position_update.trim() != '') && spareEditMode
          // }
        },
        { 
          field: "replaced_geotab_identifier", 
          headerName: "Previous Geotab ID",
          hide: true,
        },      
        { 
          field: "replaced_geotab_last_contact", 
          headerName: "Previous Geotab Last Contact",
          hide: true,
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },      
        { 
          field: "geotab_serial_number", 
          headerName: "Geotab Serial Number",
          hide: true,
        },      
        {
          field: "make",
        },
        { field: "model", cellClass: 'stringType' },
        {
          field: "engine_model",
          headerName: "Engine Model",
          hide: true,
        },
        {
          field: "year",
        },
        {
          field: "latitude",
          hide: true,
        },
        {
          field: "longitude",
          hide: true,
        },
        {
          field: "last_position_update",
          headerName: "Last Position Update",
          cellDataType: "dateTimeString",
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
        {
          headerName: "Last Contact",
          colId: "last_contact",
          valueGetter: (params) => {
            if (typeof params.data !== 'undefined') {
              var formatted_datetime = DateTime.fromISO(params.data.last_position_update);
              if (formatted_datetime.isValid)
                return DateTime.now().diff(formatted_datetime, "days").toObject()['days'] < 1 ? 0 : DateTime.now().diff(formatted_datetime, "days").toObject()['days'].toFixed(0);
              else
                return null;
            } else {
              return null;
            }
          },
          comparator: (a, b) => (a - b),
          valueFormatter: (params) => {
            if (params.value == null)
              return null;
            else if (params.value == 0)
              return "Today";
            else if (params.value == 1)
              return params.value + " day";
            else
              return params.value + " days";
          },
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: "In RTA",
          colId: "in_rta",
          cellDataType: "boolean",
          valueGetter: (params) => {
            return params.data && params.data.system_type !== "Unknown System Type";
          }
        },
        {
          field: "external_system_type",
          headerName: "External System",
        },
      ]
    });

    // const columnDefs = ref(incidentColumnDefs);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeaderHeight: true,
      wrapHeaderText: true,
    };

    const statusBar = {
      statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' }
      ]
    }

    const autoGroupColumnDef = {
      headerName: "Grouping (Row Count)",
      minWidth: 200,
      // cellRendererParams: {
      //     suppressCount: true
      // }
    };

    const dataset = ref(null);
    const report = ref(null);
    const reportName = ref('');
    const selectedGroups = ref(null);
    const integrationState = ref(null);

    const defaultExcelExportParams = ref({
      exportAsExcelTable: true,
      processRowGroupCallback(params) {
        const { node } = params;
        if (!node.footer) {
          return `${node.key}`;
        }
      }, 
    });
    
    const fetchData = () => {

      let urlSuffix = "";

      switch (dataset.value) {
        case "incidents":
          urlSuffix = ".json?q[client_id_eq]=2&q[dismissed_eq]=false";
          break;
        case "vehicles":
          urlSuffix = ".json?q[client_id_eq]=2&q[discarded_at_eq]=nil";
          break;
        case "dismissal_rules":
          urlSuffix = ".json?q[vehicle_client_id_eq]=2";
          break;
        case "user_actions":
          urlSuffix = "/users/geotab.json?";
          break;
        case "past_incidents":
          const ninetyDaysBefore = DateTime.now().minus({ days: 90 }).toISO();
          urlSuffix = "/incidents/past_incidents.json?q[client_id_eq]=2&q[incident_time_stamp_gt]=" + ninetyDaysBefore + "";
          break;
        case "vehicle_snapshots":
          urlSuffix = "/vehicles/vehicle_snapshots.json?q[client_id_eq]=2";
          break;
      }

      let url = import.meta.env.VITE_WEB_SERVER_HOST + "/" + dataset.value + urlSuffix

      if (dataset.value == "user_actions" || dataset.value == "past_incidents")
        url = import.meta.env.VITE_WEB_SERVER_HOST + urlSuffix

      // Show all if we have a top level user
      if (selectedGroups.value != "GroupCompanyId") {
        url += "&groups=" + selectedGroups.value.join(',');
      }

      fetch(url)
        .then(result => result.json())
        .then(remoteRowData => {

          // Post-process data
          if (dataset.value == "vehicles") {

            const vinCount = remoteRowData.vehicles.reduce((acc, item) => {
              const vin = item.vin.toUpperCase();
              acc[vin] = (acc[vin] || 0) + 1;
              return acc;
            }, {});

            // Add a field for is_local_orphan
            remoteRowData.vehicles.forEach(item => {
              item.is_local_orphaned = vinCount[item.vin] === 1;
            });
          }
          
          rowData.value = dataset.value === "user_actions" ? remoteRowData : remoteRowData[dataset.value];
          gridApi.value.updateGridOptions({ columnDefs: columnDefs.value[dataset.value] });

          // // Only set the report if we have a dataset and report is not set
          // if (dataset.value == "vehicles" && !report.value) {
          //   // MM Fleet Report
          //   report.value = currentSavedReports.value.find(item => item.id === 6).value;
          // }

          // If we have a selected report then apply it
          if (report.value) {
            gridApi.value.applyColumnState({
              state: report.value.columns,
              applyOrder: true,
            });
            gridApi.value.setFilterModel(report.value.filters);
          }

          gridApi.value.hideOverlay();

        });

    }

    // Watch dataset
    watch(dataset, (newDataset, previousDataset) => {
      console.log("Watch dataset changed:", newDataset);

      const resetGrid = () => {
        console.log("Resetting Grid")

        if (!gridApi.value) { 
          return;
        }
        gridApi.value.showLoadingOverlay();
        gridApi.value.setFilterModel(null);
        gridApi.value.setRowGroupColumns([]);
        gridApi.value.updateGridOptions({ pivotMode: false });
        report.value = null;
        gridApi.value.onFilterChanged();
      };

      if (selectedGroups.value) {
        resetGrid();

        fetchData(newDataset);
      }
    });

    // Watch report
    watch(report, (newReport, previousReport) => {
      console.log("Report changed:", newReport, "Dataset:", dataset.value);
      
      // If we have a blank report then do nothing
      if (!newReport || !gridApi.value)
        return;
      
      gridApi.value.showLoadingOverlay();
      gridApi.value.applyColumnState({
        state: newReport.columns,
        applyOrder: true,
      });

      let currentExcelExportParams = gridApi.value.getGridOption("defaultExcelExportParams")
      if (newReport.fileName)
        currentExcelExportParams.fileName = newReport.fileName
      else
        currentExcelExportParams.fileName = "export.xlsx"
      gridApi.value.setGridOption("defaultExcelExportParams", currentExcelExportParams)

      gridApi.value.hideOverlay();

      gridApi.value.setFilterModel(newReport.filters);

    })

    watch(integrationState, (state, previousState) => {
      console.log("Integration State changed:", state);

      var newValue = state.geotab.state.getGroupFilter().map(val => val.id);

      var current_state = state.geotab.state.getState();

      // Check for a dataset parameter
      if (current_state.dataset) {
        dataset.value = current_state.dataset;

        if (current_state.report_id) {
          // Find value in savedReports
          console.log(`Setting default report: ${current_state.report_id}`);
          report.value = currentSavedReports.value.find(item => item.id === current_state.report_id).value;
        } else {
          if (dataset.value === "vehicles") {
            // MM Fleet Report
            report.value = currentSavedReports.value.find(item => item.id === 6).value;
          }
        }
      } else {
        // Default to incidents
        console.log("Setting default due to no dataset");
        dataset.value = "vehicles";
        report.value = currentSavedReports.value.find(item => item.id === 6).value;
      }      

      const updateSelectedGroups = (newValue) => {
        if (JSON.stringify(selectedGroups.value) !== JSON.stringify(newValue)) {
          console.log("Updating selected groups to:", newValue);
          selectedGroups.value = newValue;
        } else {
          return;
        }
      };

      if (newValue == "GroupCompanyId") {
        // console.log("GroupCompanyId Detected");
        state.geotab.api.getSession(session => {
          state.geotab.api.call("Get", {
            typeName: "User",
            search: { name: session.userName }
          }, result => {
            if (result[0]) {
              updateSelectedGroups(result[0].companyGroups.map((val) => val ? val.id : null));
              // console.log("GroupCompanyId Detected, Groups from User: " + selectedGroups.value.join(','));
              if (dataset.value) {
                fetchData(dataset.value);
              }
            }
          });
        });
      } else {
        updateSelectedGroups(newValue);
        // console.log("GroupCompanyId Not Detected, Selected Groups: " + selectedGroups.value.join(','));
        if (dataset.value) {
          fetchData();
        }
      }
      
    });

    if (typeof geotab !== 'undefined') {

      var checkmateContentDiv = document.getElementById("checkmateContent")
      checkmateContentDiv.classList.add("flex", "flex-col");

      // Geotab specific code
      geotab.addin.ofhMaintBOBO = () => {
        return {
          initialize(api, state, callback) {

            api.getSession(function (credentials) {
              // var spare_edit_users = ["jared.streuli@wasteconnections.com", "kelsey.richards@wasteconnections.com", "darryl@ofh.tech", "darryl@optimumfleethealth.com", "Henry.Olivas@wasteconnections.com"]
              // if (spare_edit_users.includes(credentials.userName.toLowerCase())) {
              //   spareEditMode.value = true;
              // }
              spareEditMode.value = true;

              // const past_incidents_users = ["darryl@optimumfleethealth.com", "darryl@ofh.tech", "David.Torrey@WasteConnections.com", "jared.streuli@wasteconnections.com", "kelsey.richards@wasteconnections.com", "shane.jackson@wasteconnections.com", "gregory.thibodeaux@wasteconnections.com"]
              
              // if (!past_incidents_users.includes(credentials.userName.toLowerCase())) {
              //   const index = datasets.value.findIndex(item => item.value === "past_incidents");
              //   if (index !== -1) {
              //     datasets.value.splice(index, 1);
              //   }
              // }

              // var advanced_view_users = ["rick.mcvee@wasteconnections.com", "eric.kammerer@wasteconnections.com", "derek.stannard@wasteconnections.com", "shaun.perrin@wasteconnections.com"];
              var advanced_view_users = [];

              if (advanced_view_users.includes(credentials.userName)) {
                savedReports.value = [
                  {
                    id: 1,
                    name: "High Severity Exhaust and Brakes Report",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 138,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 138,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 174,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 570,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: {
                        "groups": {
                          "values": [
                            "7600 WCNX-Winnipeg District",
                            "7322 WCNX-Kamloops District",
                            "7321 WCNX-Kelowna District",
                            "7320 WCNX-Penticton District",
                            "7416 WCNX-Medicine Hat District",
                            "7410 WCNX-Lethbridge District",
                            "7420 WCNX-Edmonton District",
                            "7425 WCNX-Red Deer District",
                            "7400 WCNX-Calgary District"
                          ],
                          "filterType": "set"
                        },
                        "affected_system": {
                          "values": [
                            "Exhaust", "Brakes"
                          ],
                          "filterType": "set"
                        },
                        "severity": {
                          "values": [
                            "High"
                          ],
                          "filterType": "set"
                        }
                      },
                    }
                  },
                  {
                    id: 2,
                    name: "MM Activity",
                    dataset: "user_actions",
                    value: {
                      columns: [
                          {
                              "colId": "id",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "name",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "groups",
                              "width": 458,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": null
                          },
                          {
                              "colId": "regions",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "divisions",
                              "width": 180,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "first_name",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_name",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "designation",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_access_date",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": "desc",
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_action_days",
                              "width": 162.67000000000007,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          }
                      ],
                      filters: {
                        "designation": {
                            "values": [
                                "MM"
                            ],
                            "filterType": "set"
                        },                        
                      }
                    }
                  },
                  ]
              } else {
                savedReports.value = [
                  {
                    id: 3,
                    name: "MM Model/Year Breakdown",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "ag-Grid-AutoColumn",
                          "width": 256,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "region",
                          "width": 131,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 0,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 157,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "groups",
                          "width": 188,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 115,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 107,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 3,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 111,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 2,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 116,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 1,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 125,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 256,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 255.32999999999993,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: null,
                    }
                  },
                  {
                    id: 4,
                    name: "MM Year Breakdown",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "ag-Grid-AutoColumn",
                          "width": 388,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 4,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "region",
                          "width": 393,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "affected_system",
                          "width": 116,
                          "hide": true,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 2,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 1,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 171,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "groups",
                          "width": 188,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 120,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 1,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 0,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 141,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 125,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 248,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 3,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 3,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 101,
                          "hide": true,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 2,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 265.6700000000001,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: null,
                    }
                  },
                  {
                    id: 5,
                    name: "MM Incidents",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 109,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 119,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 131,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 315,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 314.6700000000001,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: null,
                    }
                  },
                  {
                    id: 6,
                    name: "MM Fleet Report",
                    dataset: "vehicles",
                    default: true,
                    value: {
                      columns: [
                        {
                            "colId": "vin_error",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },                      
                        {
                              "colId": "region",
                              "width": 149,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "division",
                              "width": 149,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "groups",
                              "width": 149,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                            "colId": "in_production",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                          },                      
                          {
                              "colId": "on_order",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "id",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "vehicle_name",
                              "width": 156,
                              "hide": false,
                              "pinned": null,
                              "sort": "asc",
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "make",
                              "width": 156,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "year",
                              "width": 156,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "model",
                              "width": 156,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "latitude",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "longitude",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_position_update",
                              "width": 168,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": 0,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": null
                          },
                          {
                              "colId": "spare",
                              "width": 100,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": null
                          },
                          {
                              "colId": "geotab_serial_number",
                              "width": 144,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": null
                          },
                          {
                              "colId": "vin",
                              "width": 199,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_contact",
                              "width": 156,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "replaced_geotab_identifier",
                              "width": 140,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": null
                          },
                          {
                              "colId": "replaced_geotab_last_contact",
                              "width": 156,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "external_system_type",
                              "width": 149,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "system_type",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "pre_install_serial",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "engine_model",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "in_rta",
                              "width": 152,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": null
                          },
                      ],
                      filters: {
                          "external_system_type": {
                              "values": [
                                  "geotab"
                              ],
                              "filterType": "set"
                          }
                      },
                    }
                  },
                  {
                    id: 7,
                    name: "3. RTA VIN's to install Geotabs",
                    dataset: "vehicles",
                    default: false,
                    autoExpand: true,
                    value: {
                      fileName: "WeeklyScorecardRTAUninstalled.xlsx",
                      columns: [
                        {
                            "colId": "region",
                            "width": 157,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "division",
                            "width": 157,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "groups",
                            "width": 157,
                            "hide": true,
                            "pinned": null,
                            "sort": "asc",
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 0,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "id",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vehicle_name",
                            "width": 138,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vin",
                            "width": 199,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                          "colId": "vin_error",
                          "width": 200,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "make",
                            "width": 117,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "model",
                            "width": 117,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "year",
                            "width": 117,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                          "colId": "is_trailer",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "latitude",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "longitude",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "last_position_update",
                            "width": 135,
                            "hide": false,
                            "pinned": null,
                            "sort": "desc",
                            "sortIndex": 0,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "spare",
                            "width": 100,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "geotab_serial_number",
                            "width": 144,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "last_contact",
                            "width": 132,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "replaced_geotab_identifier",
                            "width": 140,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "replaced_geotab_last_contact",
                            "width": 164,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "external_system_type",
                            "width": 152,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "system_type",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "pre_install_serial",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "engine_model",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "in_rta",
                            "width": 152,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },                
                        {
                          "colId": "in_production",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "on_order",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                      ],
                      filters: {
                        // "in_production": {
                        //     "values": [
                        //         "false"
                        //     ],
                        //     "filterType": "set"
                        // },                        
                        "on_order": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },                        
                        "is_rta_uninstalled": {
                            "values": [
                                "true"
                            ],
                            "filterType": "set"
                        },
                        "system_type": {
                          "values": [ 'Front Load', 'Roll Off', 'Rear Load', 'Automated', 'Side Load',
                            'Front Load AFL', 'Cont. Del', 'Tractor', 'Pumper', 'Hook Lift', 'Retriever',
                            'Grapple Truck', 'Box Van', 'Recycle', 'Flat Bed Truck',
                            'Boom Truck', 'Dump Truck', 'Water Truck', 'Molok', 
                            'STARR-Tractor', 'Unknown System Type']
                        },
                        "external_system_type": {
                              "values": [
                                  "rta"
                              ],
                              "filterType": "set"
                        },
                        "scrap_vin": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },
                        "year": {
                              "filterType": "number",
                              "type": "greaterThan",
                              "filter": 1999
                        },                        
                      },
                    }
                  },         
                  {
                    id: 29,
                    name: "6. Installed GeoTab Without RTA VIN Match",
                    dataset: "vehicles",
                    default: false,
                    autoExpand: true,
                    value: {
                      fileName: "WeeklyScorecardMismatchedGeotabs.xlsx",
                      columns: [
                        {
                            "colId": "region",
                            "width": 157,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 0,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "division",
                            "width": 157,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 1,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "groups",
                            "width": 157,
                            "hide": true,
                            "pinned": null,
                            "sort": "asc",
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 2,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "id",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vehicle_name",
                            "width": 138,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vin",
                            "width": 199,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                          "colId": "vin_error",
                          "width": 200,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                          "colId": "scrap_vin",
                          "width": 200,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "make",
                            "width": 117,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "model",
                            "width": 117,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "year",
                            "width": 117,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                          "colId": "is_trailer",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "latitude",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "longitude",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "last_position_update",
                            "width": 135,
                            "hide": false,
                            "pinned": null,
                            "sort": "desc",
                            "sortIndex": 0,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "spare",
                            "width": 100,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "geotab_serial_number",
                            "width": 144,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "last_contact",
                            "width": 132,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "replaced_geotab_identifier",
                            "width": 140,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "replaced_geotab_last_contact",
                            "width": 164,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "external_system_type",
                            "width": 152,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "system_type",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "pre_install_serial",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "engine_model",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "in_rta",
                            "width": 152,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },                
                        {
                          "colId": "in_production",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "on_order",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                      ],
                      filters: {
                        "in_production": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },
                        "is_trailer": {
                          "values": [
                              "false"
                          ],
                          "filterType": "set"
                        },
                        "on_order": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },                        
                        "is_local_orphaned": {
                            "values": [
                                "true"
                            ],
                            "filterType": "set"
                        },
                        "system_type": {
                          "values": [ 'Front Load', 'Roll Off', 'Rear Load', 'Automated', 'Side Load',
                            'Front Load AFL', 'Cont. Del', 'Tractor', 'Pumper', 'Hook Lift', 'Retriever',
                            'Grapple Truck', 'Box Van', 'Recycle', 'Flat Bed Truck',
                            'Boom Truck', 'Dump Truck', 'Water Truck', 'Molok', 
                            'STARR-Tractor', 'Unknown System Type']
                        },
                        "external_system_type": {
                              "values": [
                                  "geotab"
                              ],
                              "filterType": "set"
                        },
                        "year": {
                              "filterType": "number",
                              "type": "greaterThan",
                              "filter": 1999
                        },                        
                      },
                    }
                  },                           
                  {
                    id: 8,
                    name: "MM Exhaust Incidents",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 109,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 119,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 131,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 315,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 314.6700000000001,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: {
                        "affected_system": {
                          "values": [
                            "Exhaust"
                          ],
                          "filterType": "set"
                        }
                      },
                    }
                  },
                  {
                    id: 9,
                    name: "MM Brakes Incidents",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 109,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 119,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 131,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 315,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 314.6700000000001,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: {
                        "affected_system": {
                          "values": [
                            "Brakes"
                          ],
                          "filterType": "set"
                        }
                      },
                    }
                  },
                  {
                    id: 10,
                    name: "MM Engine Incidents",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 109,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 119,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 131,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 315,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 314.6700000000001,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: {
                        "affected_system": {
                          "values": [
                            "Engine"
                          ],
                          "filterType": "set"
                        }
                      },
                    }
                  },
                  {
                    id: 11,
                    name: "RMM Incidents",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 109,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 109,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 174,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 210,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 105,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: null,
                    }
                  },
                  {
                    id: 12,
                    name: "RMM Vehicles",
                    dataset: "vehicles",
                    value: {
                      columns: [
                          {
                              "colId": "vin_error",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },                      
                        {
                          "colId": "region",
                          "width": 131,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 131,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 430.6700000000001,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "in_production",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "on_order",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": "asc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 152,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 144,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 158,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "latitude",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "longitude",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "last_position_update",
                          "width": 198,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "last_contact",
                          "width": 147,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "external_system_type",
                          "width": 169,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: null,
                    }
                  },
                  {
                    id: 13,
                    name: "RMM Model/Year Breakdown",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "ag-Grid-AutoColumn",
                          "width": 206,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "region",
                          "width": 119,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 0,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 157,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "groups",
                          "width": 188,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 115,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 107,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 3,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 111,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 2,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 116,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 1,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 125,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: null,
                    }
                  },
                  {
                    id: 14,
                    name: "RMM Year Breakdown",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "ag-Grid-AutoColumn",
                          "width": 170,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 4,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 188,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "region",
                          "width": 119,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "affected_system",
                          "width": 119,
                          "hide": true,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 2,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 1,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 171,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 120,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 1,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 0,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 141,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 125,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 248,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 3,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 3,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 101,
                          "hide": true,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 2,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 170,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: null,
                    }
                  },
                  {
                    id: 15,
                    name: "RMM Brake Incidents",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 128,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 128,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 174,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 428,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: {
                        "affected_system": {
                          "values": [
                            "Brakes"
                          ],
                          "filterType": "set"
                        }
                      },
                    }
                  },
                  {
                    id: 16,
                    name: "RMM Engine Incidents",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 128,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 128,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 174,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 428,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: {
                        "affected_system": {
                          "values": [
                            "Engine"
                          ],
                          "filterType": "set"
                        }
                      },
                    }
                  },
                  {
                    id: 17,
                    name: "RMM Exhaust Incidents",
                    dataset: "incidents",
                    value: {
                      columns: [
                        {
                          "colId": "region",
                          "width": 128,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "division",
                          "width": 128,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "groups",
                          "width": 174,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "severity",
                          "width": 121,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vehicle_name",
                          "width": 137,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "make",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "year",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "model",
                          "width": 127,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "affected_system",
                          "width": 105,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "emerging_problem",
                          "width": 428,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "incident_time_stamp",
                          "width": 114,
                          "hide": false,
                          "pinned": null,
                          "sort": "desc",
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        },
                        {
                          "colId": "vin",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "corrective_procedure",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "current_condition",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "protocol",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },
                        {
                          "colId": "status",
                          "width": 110,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                        }
                      ],
                      filters: {
                        "affected_system": {
                          "values": [
                            "Exhaust"
                          ],
                          "filterType": "set"
                        }
                      },
                    },
                  },
                  {
                    id: 18,
                    name: "4. Weekly Scorecard Uninstalled Geotabs",
                    dataset: "vehicles",
                    value: {
                      fileName: "WeeklyScorecardUninstalledGeotabs.xlsx",
                      columns: [
                          {
                              "colId": "vin_error",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },                      
                          {
                              "colId": "region",
                              "width": 126,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": true,
                              "rowGroupIndex": 0,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "division",
                              "width": 420,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": true,
                              "rowGroupIndex": 1,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "groups",
                              "width": 126,
                              "hide": true,
                              "pinned": null,
                              "sort": "asc",
                              "sortIndex": 0,
                              "aggFunc": null,
                              "rowGroup": true,
                              "rowGroupIndex": 2,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "vin",
                              "width": 140,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "system_type",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                            "colId": "is_trailer",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                            },                      
                          {
                              "colId": "id",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "vehicle_name",
                              "width": 420,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "pre_install_serial",
                              "width": 315,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "spare",
                              "width": 420,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "make",
                              "width": 158,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "model",
                              "width": 180,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "engine_model",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "year",
                              "width": 210,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "latitude",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "longitude",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_position_update",
                              "width": 315,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "external_system_type",
                              "width": 252,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "replaced_geotab_identifier",
                              "width": 158,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                            "colId": "replaced_geotab_last_contact",
                            "width": 150,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                          },
                          {
                            "colId": "last_contact",
                            "width": 155.32999999999993,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                          },
                          {
                          "colId": "in_production",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "on_order",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                      ],
                      filters: {
                        // "pre_install_serial": {
                        //     "values": [
                        //         "true"
                        //     ],
                        //     "filterType": "set"
                        // },
                        // "spare": {
                        //     "values": [
                        //         "false"
                        //     ],
                        //     "filterType": "set"
                        // },
                        "is_trailer": {
                          "values": [
                              "false"
                          ],
                          "filterType": "set"
                        },
                        // "in_production": {
                        //     "values": [
                        //         "false"
                        //     ],
                        //     "filterType": "set"
                        // },                        
                        "on_order": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },                        
                        "external_system_type": {
                              "values": [
                                  "geotab"
                              ],
                              "filterType": "set"
                        },
                        "last_position_update": {
                            "dateFrom": null,
                            "dateTo": null,
                            "filterType": "date",
                            "type": "blank"
                        },
                        "scrap_vin": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },
                      },
                    }
                  },
                  {
                    id: 19,
                    name: "Geotab Spares",
                    dataset: "vehicles",
                    value: {
                      columns: [
                          {
                              "colId": "vin_error",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },                      
                          {
                              "colId": "region",
                              "width": 126,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": true,
                              "rowGroupIndex": 0,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "division",
                              "width": 420,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "groups",
                              "width": 126,
                              "hide": true,
                              "pinned": null,
                              "sort": "asc",
                              "sortIndex": 0,
                              "aggFunc": null,
                              "rowGroup": true,
                              "rowGroupIndex": 1,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                            "colId": "in_production",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                          },                      
                          {
                              "colId": "on_order",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "vin",
                              "width": 140,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "system_type",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "id",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "vehicle_name",
                              "width": 420,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "pre_install_serial",
                              "width": 315,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "spare",
                              "width": 420,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "make",
                              "width": 158,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "model",
                              "width": 180,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "engine_model",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "year",
                              "width": 210,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "latitude",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "longitude",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_position_update",
                              "width": 315,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "external_system_type",
                              "width": 252,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "replaced_geotab_identifier",
                              "width": 158,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                            "colId": "replaced_geotab_last_contact",
                            "width": 150,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                          },
                          {
                              "colId": "last_contact",
                              "width": 155.32999999999993,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          }
                      ],      
                      filters: {
                        "spare": {
                            "values": [
                                "true"
                            ],
                            "filterType": "set"
                        },
                      },
                    }
                  },                  
                  // {
                  //   id: 20,
                  //   name: "Geotab Audit",
                  //   dataset: "vehicles",
                  //   value: {
                  //     columns: [
                  //       {
                  //         "colId": "region",
                  //         "width": 148,
                  //         "hide": true,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": true,
                  //         "rowGroupIndex": 0,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "division",
                  //         "width": 181,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "groups",
                  //         "width": 163,
                  //         "hide": true,
                  //         "pinned": null,
                  //         "sort": "asc",
                  //         "sortIndex": 0,
                  //         "aggFunc": null,
                  //         "rowGroup": true,
                  //         "rowGroupIndex": 1,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "vin",
                  //         "width": 200,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "system_type",
                  //         "width": 200,
                  //         "hide": true,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "id",
                  //         "width": 200,
                  //         "hide": true,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "vehicle_name",
                  //         "width": 181,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "pre_install_serial",
                  //         "width": 181,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "make",
                  //         "width": 181,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "model",
                  //         "width": 181,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "engine_model",
                  //         "width": 200,
                  //         "hide": true,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "year",
                  //         "width": 181,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "latitude",
                  //         "width": 200,
                  //         "hide": true,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "longitude",
                  //         "width": 200,
                  //         "hide": true,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "last_position_update",
                  //         "width": 181,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "last_contact",
                  //         "width": 181,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       },
                  //       {
                  //         "colId": "external_system_type",
                  //         "width": 177.32999999999993,
                  //         "hide": false,
                  //         "pinned": null,
                  //         "sort": null,
                  //         "sortIndex": null,
                  //         "aggFunc": null,
                  //         "rowGroup": false,
                  //         "rowGroupIndex": null,
                  //         "pivot": false,
                  //         "pivotIndex": null,
                  //         "flex": 1
                  //       }
                  //     ],
                  //     filters: {
                  //       "pre_install_serial": {
                  //           "values": [
                  //               "true"
                  //           ],
                  //           "filterType": "set"
                  //       },
                  //       "last_position_update": {
                  //           "dateFrom": null,
                  //           "dateTo": null,
                  //           "filterType": "date",
                  //           "type": "notBlank"
                  //       }
                  //     },
                  //   }
                  // },
                  {
                    id: 21,
                    name: "MM Activity",
                    dataset: "user_actions",
                    value: {
                      columns: [
                          {
                              "colId": "id",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "name",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "groups",
                              "width": 458,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": null
                          },
                          {
                              "colId": "regions",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "divisions",
                              "width": 180,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "first_name",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_name",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "designation",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_access_date",
                              "width": 164,
                              "hide": false,
                              "pinned": null,
                              "sort": "desc",
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "last_action_days",
                              "width": 162.67000000000007,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          }
                      ],
                      filters: {
                        "designation": {
                            "values": [
                                "MM"
                            ],
                            "filterType": "set"
                        },                        
                      }
                    }
                  },
                  {
                    id: 22,
                    name: "Western 60 Day Exhaust Incidents",
                    dataset: "past_incidents",
                    value: {
                      columns: [
                        {
                            "colId": "groups",
                            "width": 100,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 0,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "emerging_problem",
                            "width": 125,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "affected_system",
                            "width": 125,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vehicle_name",
                            "width": 125,
                            "hide": false,
                            "pinned": null,
                            "sort": 'asc',
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 1,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "make",
                            "width": 125,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "low_its",
                            "width": 125,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "medium_its",
                            "width": 125,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "high_its",
                            "width": 125,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "latest_its",
                            "width": 125,
                            "hide": false,
                            "pinned": null,
                            "sort": "desc",
                            "sortIndex": 0,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "pm_its",
                            "width": 125,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "maint_its",
                            "width": 125,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "complete_its",
                            "width": 125,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vin",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "system_type",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "model",
                            "width": 106,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "engine_model",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "year",
                            "width": 113.67000000000007,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "chain_incident_id",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        }
                    ],                   
                      filters: {
                        "groups": {
                            "values": [
                              "4032 WCNX - Clearlake Waste District",
                              "4026 WCNX - Livermore Sanitation District",
                              "4023 WCNX - Garden City District",
                              "4060 WCNX -  Upper Valley Disposal Services District",
                              "4014M WCNX - Mammoth Disposal District",
                              "4034  WCNX -  Ukiah Waste District",
                              "4014B WCNX - Bishop Disposal District",
                              "4110 WCNX - San Luis Obispo District",
                              "4037 WCNX - C&S Lassen District",
                              "4030 WCNX - El Dorado Disposal District",
                              "4025 WCNX - West Valley Cities District",
                              "4020 WCNX - Green Team District",
                              "4018 WCNX - Tehama Red Bluff Hauling District"
                            ],
                            "filterType": "set"
                        },
                        "latest_its": {
                          "dateFrom": new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().split('T')[0] + ' 00:00:00',
                          "dateTo": null,
                          "filterType": "date",
                          "type": "greaterThan"
                        },
                        "affected_system": {
                            "values": [
                                "043"
                            ],
                            "filterType": "set"
                        }                                                
                      },                      
                    }
                  },
                  {
                    id: 23,
                    name: "5. Weekly Scorecard 30 Day Inactive",
                    dataset: "vehicles",
                    value: {
                      fileName: "WeeklyScorecard30DayInactive.xlsx",
                      columns: [
                        {
                            "colId": "vin_error",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },                      
                        {
                            "colId": "region",
                            "width": 151,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 0,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "division",
                            "width": 151,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 1,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "groups",
                            "width": 151,
                            "hide": true,
                            "pinned": null,
                            "sort": "asc",
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": true,
                            "rowGroupIndex": 2,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "id",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vehicle_name",
                            "width": 158,
                            "hide": false,
                            "pinned": null,
                            "sort": "asc",
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "make",
                            "width": 158,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "year",
                            "width": 158,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "model",
                            "width": 158,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                          "colId": "is_trailer",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "vin",
                            "width": 200,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },                        
                        {
                            "colId": "latitude",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "longitude",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "last_position_update",
                            "width": 168,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": 0,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "spare",
                            "width": 100,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "geotab_serial_number",
                            "width": 144,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "last_contact",
                            "width": 158,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "replaced_geotab_identifier",
                            "width": 140,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "replaced_geotab_last_contact",
                            "width": 158,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "external_system_type",
                            "width": 150,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "system_type",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "pre_install_serial",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "engine_model",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "in_rta",
                            "width": 158,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                          "colId": "in_production",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "on_order",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                      ],                   
                      filters: {
                          "is_trailer": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                          },
                          // "in_production": {
                          //     "values": [
                          //         "false"
                          //     ],
                          //     "filterType": "set"
                          // },                        
                          "on_order": {
                              "values": [
                                  "false"
                              ],
                              "filterType": "set"
                          },                        
                          "external_system_type": {
                              "values": [
                                  "geotab"
                              ],
                              "filterType": "set"
                          },
                          "last_position_update": {
                              "filterType": "date",
                              "type": "lessThan",
                              "dateFrom": DateTime.now().minus({ days: 30 }).toFormat('yyyy-LL-dd HH:mm:ss'),
                              "dateTo": null,
                          },
                          "scrap_vin": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                          },
                          "system_type": {
                            "values": [ 'Front Load', 'Roll Off', 'Rear Load', 'Automated', 'Side Load',
                              'Front Load AFL', 'Cont. Del', 'Tractor', 'Pumper', 'Hook Lift', 'Retriever',
                              'Grapple Truck', 'Box Van', 'Recycle', 'Flat Bed Truck',
                              'Boom Truck', 'Dump Truck', 'Water Truck', 'Molok', 
                              'STARR-Tractor', 'Unknown System Type']
                          },                          
                          "year": {
                              "filterType": "number",
                              "type": "greaterThan",
                              "filter": 1999
                            }
                      },                      
                    }
                  },
                  {
                    id: 24,
                    name: "Active California CARB Report",
                    dataset: "incidents",
                    value: {
                      columns: [
                          {
                              "colId": "region",
                              "width": 164,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": true,
                              "rowGroupIndex": 0,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "division",
                              "width": 184,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": true,
                              "rowGroupIndex": 1,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "id",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "incident_time_stamp",
                              "width": 236,
                              "hide": false,
                              "pinned": null,
                              "sort": "desc",
                              "sortIndex": 0,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "severity",
                              "width": 236,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "groups",
                              "width": 207,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": true,
                              "rowGroupIndex": 2,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "vehicle_name",
                              "width": 236,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "vin",
                              "width": 136,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "system_type",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "emerging_problem",
                              "width": 236,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "make",
                              "width": 236,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "model",
                              "width": 117,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "engine_model",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "year",
                              "width": 117,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "affected_system",
                              "width": 236,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "corrective_procedure",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "current_condition",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "protocol",
                              "width": 200,
                              "hide": true,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          },
                          {
                              "colId": "status",
                              "width": 236,
                              "hide": false,
                              "pinned": null,
                              "sort": null,
                              "sortIndex": null,
                              "aggFunc": null,
                              "rowGroup": false,
                              "rowGroupIndex": null,
                              "pivot": false,
                              "pivotIndex": null,
                              "flex": 1
                          }
                      ],        
                      filters: {
                        "groups": {
                            "values": [
                              "4032 WCNX - Clearlake Waste District",
                              "4026 WCNX - Livermore Sanitation District",
                              "4023 WCNX - Garden City District",
                              "4060 WCNX -  Upper Valley Disposal Services District",
                              "4014M WCNX - Mammoth Disposal District",
                              "4034  WCNX -  Ukiah Waste District",
                              "4014B WCNX - Bishop Disposal District",
                              "4110 WCNX - San Luis Obispo District",
                              "4037 WCNX - C&S Lassen District",
                              "4030 WCNX - El Dorado Disposal District",
                              "4025 WCNX - West Valley Cities District",
                              "4020 WCNX - Green Team District",
                              "4018 WCNX - Tehama Red Bluff Hauling District"
                            ],
                            "filterType": "set"
                        },
                        "affected_system": {
                            "values": [
                                "Exhaust"
                            ],
                            "filterType": "set"
                        }                                                
                      },                      
                    }
                  },
                  // {
                  //   id: 25,
                  //   name: "Full Fleet Vehicle Audit",
                  //   dataset: "vehicles",
                  //   value: {
                  //     columns: [
                  //       {
                  //           "colId": "region",
                  //           "width": 148,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": true,
                  //           "rowGroupIndex": 1,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "division",
                  //           "width": 166,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": true,
                  //           "rowGroupIndex": 2,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "id",
                  //           "width": 200,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "vehicle_name",
                  //           "width": 211,
                  //           "hide": false,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "groups",
                  //           "width": 187,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": true,
                  //           "rowGroupIndex": 3,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "vin",
                  //           "width": 211,
                  //           "hide": false,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "make",
                  //           "width": 211,
                  //           "hide": false,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "year",
                  //           "width": 211,
                  //           "hide": false,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "model",
                  //           "width": 211,
                  //           "hide": false,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "latitude",
                  //           "width": 200,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "longitude",
                  //           "width": 200,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "last_position_update",
                  //           "width": 168,
                  //           "hide": false,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": null
                  //       },
                  //       {
                  //           "colId": "spare",
                  //           "width": 100,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": null
                  //       },
                  //       {
                  //           "colId": "geotab_serial_number",
                  //           "width": 144,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": null
                  //       },
                  //       {
                  //           "colId": "last_contact",
                  //           "width": 211,
                  //           "hide": false,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "replaced_geotab_identifier",
                  //           "width": 140,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": null
                  //       },
                  //       {
                  //           "colId": "replaced_geotab_last_contact",
                  //           "width": 167,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "external_system_type",
                  //           "width": 211,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": "asc",
                  //           "sortIndex": 0,
                  //           "aggFunc": null,
                  //           "rowGroup": true,
                  //           "rowGroupIndex": 0,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "system_type",
                  //           "width": 200,
                  //           "hide": false,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "pre_install_serial",
                  //           "width": 200,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "engine_model",
                  //           "width": 200,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "is_orphaned",
                  //           "width": 149,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       },
                  //       {
                  //           "colId": "in_rta",
                  //           "width": 134,
                  //           "hide": true,
                  //           "pinned": null,
                  //           "sort": null,
                  //           "sortIndex": null,
                  //           "aggFunc": null,
                  //           "rowGroup": false,
                  //           "rowGroupIndex": null,
                  //           "pivot": false,
                  //           "pivotIndex": null,
                  //           "flex": 1
                  //       }
                  //   ],        
                  //     filters: {
                  //         "is_orphaned": {
                  //             "values": [
                  //                 "true"
                  //             ],
                  //             "filterType": "set"
                  //         }
                  //     },                      
                  //   }
                  // },
                  {
                    id: 26,
                    name: "Econic Watchdog",
                    dataset: "vehicles",
                    value: {
                      "columns": [
                        {
                            "colId": "vin_error",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },                      
                        {
                            "colId": "region",
                            "width": 223,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "division",
                            "width": 223,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "groups",
                            "width": 223,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                          "colId": "in_production",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                        },                      
                        {
                            "colId": "on_order",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "id",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vehicle_name",
                            "width": 223,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "vin",
                            "width": 223,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "make",
                            "width": 118,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "model",
                            "width": 144,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "latitude",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "longitude",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "last_position_update",
                            "width": 168,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "spare",
                            "width": 100,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "odometer",
                            "width": 223,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "engine_hours",
                            "width": 223,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "year",
                            "width": 223,
                            "hide": false,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "geotab_serial_number",
                            "width": 144,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "last_contact",
                            "width": 219,
                            "hide": false,
                            "pinned": null,
                            "sort": "desc",
                            "sortIndex": 0,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "replaced_geotab_identifier",
                            "width": 140,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "replaced_geotab_last_contact",
                            "width": 185,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "external_system_type",
                            "width": 154,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "system_type",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "pre_install_serial",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "engine_model",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        },
                        {
                            "colId": "in_rta",
                            "width": 152,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": null
                        },
                        {
                            "colId": "is_rta_uninstalled",
                            "width": 200,
                            "hide": true,
                            "pinned": null,
                            "sort": null,
                            "sortIndex": null,
                            "aggFunc": null,
                            "rowGroup": false,
                            "rowGroupIndex": null,
                            "pivot": false,
                            "pivotIndex": null,
                            "flex": 1
                        }
                      ],
                      "filters": {
                          "external_system_type": {
                              "values": [
                                  "geotab"
                              ],
                              "filterType": "set"
                          },
                          "groups": {
                              "values": [
                                  "EC- Econic Vehicles"
                              ],
                              "filterType": "set"
                          },
                          // "vehicle_name": {
                          //     "filterType": "text",
                          //     "type": "notContains",
                          //     "filter": "History"
                          // }
                      },
                    }
                  },
                  {
                    id: 27,
                    name: "1. Weekly Scorecard Installed Geotab Vehicle Count",
                    dataset: "vehicles",
                    default: false,
                    value: {
                      fileName: "WeeklyScorecardInstalledGeotabVehicleCount.xlsx",
                      columns: [
                      {
                          "colId": "vin_error",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },                      
                      {
                          "colId": "region",
                          "width": 164,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 0,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "division",
                          "width": 164,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 1,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "groups",
                          "width": 164,
                          "hide": true,
                          "pinned": null,
                          "sort": "asc",
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 2,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "vehicle_name",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": "asc",
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "make",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "year",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "model",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "system_type",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "is_trailer",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },                      
                      {
                          "colId": "latitude",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "longitude",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "last_position_update",
                          "width": 168,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "spare",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "geotab_serial_number",
                          "width": 144,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "vin",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "last_contact",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "replaced_geotab_identifier",
                          "width": 140,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "replaced_geotab_last_contact",
                          "width": 130,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "external_system_type",
                          "width": 164,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "pre_install_serial",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "engine_model",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "in_rta",
                          "width": 152,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "is_rta_uninstalled",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "in_production",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },                      
                      {
                          "colId": "on_order",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "odometer",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "engine_hours",
                          "width": 129,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      }
                      ],
                      filters: {
                        // "pre_install_serial": {
                        //   "values": [
                        //       "false"
                        //   ],
                        //   "filterType": "set"
                        // },
                        "is_trailer": {
                          "values": [
                              "false"
                          ],
                          "filterType": "set"
                        },
                        // "in_production": {
                        //     "values": [
                        //         "false"
                        //     ],
                        //     "filterType": "set"
                        // },                        
                        "on_order": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },                        
                        "spare": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },
                        "system_type": {
                          "values": [ 'Front Load', 'Roll Off', 'Rear Load', 'Automated', 'Side Load',
                            'Front Load AFL', 'Cont. Del', 'Tractor', 'Pumper', 'Hook Lift', 'Retriever',
                            'Grapple Truck', 'Box Van', 'Recycle', 'Flat Bed Truck',
                            'Boom Truck', 'Dump Truck', 'Water Truck', 'Molok', 
                            'STARR-Tractor', 'Unknown System Type'],
                          "filterType": "set"
                        },
                        "last_position_update": {
                            "dateFrom": null,
                            "dateTo": null,
                            "filterType": "date",
                            "type": "notBlank"
                        },
                        "external_system_type": {
                            "values": [
                                "geotab"
                            ],
                            "filterType": "set"
                        },
                        "scrap_vin": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },
                        "year": {
                              "filterType": "number",
                              "type": "greaterThan",
                              "filter": 1999
                            },                        
                      },
                    }
                  },                  
                  {
                    id: 28,
                    name: "2. Weekly Scorecard RTA Vehicle Count",
                    dataset: "vehicles",
                    default: false,
                    autoExpand: true,
                    value: {
                      fileName: "WeeklyScorecardRTAVehicleCount.xlsx",
                      columns: [
                      {
                          "colId": "vin_error",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },                      
                      {
                          "colId": "region",
                          "width": 164,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "division",
                          "width": 164,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "groups",
                          "width": 164,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": true,
                          "rowGroupIndex": 0,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "id",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "vehicle_name",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": "asc",
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "make",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "year",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "model",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "system_type",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "is_trailer",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },                      
                      {
                          "colId": "latitude",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "longitude",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "last_position_update",
                          "width": 168,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": 0,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "spare",
                          "width": 100,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "geotab_serial_number",
                          "width": 144,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "vin",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "last_contact",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "replaced_geotab_identifier",
                          "width": 140,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "replaced_geotab_last_contact",
                          "width": 130,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "external_system_type",
                          "width": 164,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "pre_install_serial",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "engine_model",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "in_rta",
                          "width": 152,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": null
                      },
                      {
                          "colId": "is_rta_uninstalled",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "in_production",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },                      
                      {
                          "colId": "on_order",
                          "width": 200,
                          "hide": true,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "odometer",
                          "width": 130,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      },
                      {
                          "colId": "engine_hours",
                          "width": 129,
                          "hide": false,
                          "pinned": null,
                          "sort": null,
                          "sortIndex": null,
                          "aggFunc": null,
                          "rowGroup": false,
                          "rowGroupIndex": null,
                          "pivot": false,
                          "pivotIndex": null,
                          "flex": 1
                      }
                  ],
                      filters: {
                        // "in_production": {
                        //     "values": [
                        //         "false"
                        //     ],
                        //     "filterType": "set"
                        // },                        
                        "on_order": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },                        
                        "system_type": {
                          "values": [ 'Front Load', 'Roll Off', 'Rear Load', 'Automated', 'Side Load',
                            'Front Load AFL', 'Cont. Del', 'Tractor', 'Pumper', 'Hook Lift', 'Retriever',
                            'Grapple Truck', 'Box Van', 'Recycle', 'Flat Bed Truck',
                            'Boom Truck', 'Dump Truck', 'Water Truck', 'Molok', 
                            'STARR-Tractor', 'Unknown System Type'],
                          "filterType": "set"
                        },
                        "external_system_type": {
                            "values": [
                                "rta"
                            ],
                            "filterType": "set"
                        },
                        "scrap_vin": {
                            "values": [
                                "false"
                            ],
                            "filterType": "set"
                        },
                        "year": {
                              "filterType": "number",
                              "type": "greaterThan",
                              "filter": 1999
                        }                        
                      },
                    }
                  },                  
                ];
              }

              // geotab_username = credentials.userName;
            });

            console.log("Geotab initialize");
            callback();
          },
          focus(api, state) {
            // Update our state
            integrationState.value = {
              "geotab": {
                api: api,
                state: state,
              },
            };

            advancedMode.value = state.getState().advancedMode == 'true' || false;

            console.log("Geotab Focus");
          },
          blur(api, state) {
            // Save any Add-In state
            console.log("Geotab blur");
          }
        };
      };
    }

    onBeforeMount(() => {
      dataTypeDefinitions.value = {
        dateTimeString: {
          baseDataType: 'dateString',
          extendsDataType: 'dateString',
          valueParser: (params) =>
            params.newValue != null &&
            params.newValue.match('\\d{2}/\\d{2}/\\d{4}')
              ? params.newValue
              : null,
          valueFormatter: (params) => {
            var formatted_datetime = DateTime.fromISO(params.value);
            if (formatted_datetime.isValid)
              return formatted_datetime.toFormat('yyyy-MM-dd t');
            else
              return "";
          },
          dataTypeMatcher: (value) =>
            typeof value === 'string' && !!value.match('\\d{2}/\\d{2}/\\d{4}'),
          dateParser: (value) => {
            if (value == null || value === '') {
              return undefined;
            }
            const date = new Date(value);
            return isNaN(date) ? undefined : date;
          },
          dateFormatter: (value) => {
            if (value == null) {
              return undefined;
            }
            if (!(value instanceof Date)) {
              value = new Date(value);
            }
            return value.toLocaleDateString(navigator.language || 'en-US');
          },
          comparator: (valueA, valueB) => {
            const dateA = DateTime.fromISO(valueA);
            const dateB = DateTime.fromISO(valueB);

            if (dateA < dateB) {
              return -1;
            } else if (dateA > dateB) {
              return 1;
            } else {
              return 0;
            }
          }
        },
      };
    });

    // Example load data from sever
    onMounted(() => {
    });

    // possible options: 'never', 'always', 'onlyWhenGrouping'
    const rowGroupPanelShow = "always";

    return {
      onGridReady,
      onGridStateUpdated,
      columnDefs,
      autoGroupColumnDef,
      rowData,
      defaultColDef,
      rowGroupPanelShow,
      onSaveClick,
      onResetClick,
      onSaveDialogCancelClick,
      dataset,
      report,
      reportName,
      selectedGroups,
      integrationState,
      savedReports,
      currentSavedReports,
      saveDialogOpen,
      advancedMode,
      onCellValueChanged,
      dataTypeDefinitions,
      datasets,
      excelStyles,
      statusBar,
      groupDefaultExpanded,
      defaultExcelExportParams,
    };
  },
};
</script>import { data } from 'autoprefixer';
import { data } from 'autoprefixer';

