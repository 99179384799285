<template>
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">

        <div class="overflow-hidden rounded-lg bg-white shadow">
            <div class="px-4 py-5 sm:p-6">
                <h3>This is the dashboard component</h3>
                <h6>You can find me in: <span class="px-2 py-1 bg-slate-100">frontend/components/views/Dashboard.vue</span></h6>
            </div>
        </div>        
    </div>
</template>