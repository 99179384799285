<template>
  <form>
    <div class="pl-2 py-2">
      <div class="grid grid-cols-4 gap-4 bg-gray-50">
        <div>
          <label for="client" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Client</label>
          <div class="mt-1 sm:col-span-2 sm:mt-0">
            <select v-model="selectedClient" id="locale" name="client"
              class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm">
              <option v-for="client in clients" :value="client.id">
                {{ client.name }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <label for="startDate" class="block text-sm font-medium text-gray-700">Start date</label>
          <div class="mt-1">
            <input @change="onStartDateChange($event.target.value)" v-model="startDate" type="date" name="startDate" id="startDate" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
          </div>
        </div>
      </div>
    </div>
  </form>  
  <ag-grid-vue class="ag-theme-alpine flex-1 flex flex-col" :columnDefs="columnDefs" :rowData="rowData"
    :defaultColDef="defaultColDef" :animateRows="true" :rowGroupPanelShow="true" :sideBar="true"
    @grid-ready="onGridReady">
  </ag-grid-vue>
</template>

<script>

import { DateTime } from 'luxon';
import { watch, computed, reactive, onMounted, ref } from "vue";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

export default {
  name: "App",
  components: {
    AgGridVue,
  },
  setup() {
    const gridApi = ref(null); // Optional - for accessing Grid's API
    const gridColumnApi = ref(null);
    const rowData = ref(null);
    const selectedClient = ref(2);
    const startDate = ref(DateTime.now().toISODate());

    const clients = ref([
      { id: 2, name: "WCNX" },
      { id: 1181, name: "Walgreens" },
    ]);

    // Obtain API from grid's onGridReady event
    const onGridReady = (params) => {
      gridApi.value = params.api;
      gridColumnApi.value = params.columnApi;
    };

    // DefaultColDef sets props common to all Columns
    const defaultColDef = {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
      resizable: true
    };    

    const columnDefs = [
        { field: "client_id" },
        { field: "username" },
        { field: "action" },
        { 
          field: "parameters",
          valueFormatter: (data) => {
            return JSON.stringify(data.value, null, "\t");
          }
        },
        { 
          field: "created_at",
          sort: 'desc',
          headerName: "Timestamp",
          valueFormatter: (data) => {
            var formatted_datetime = DateTime.fromISO(data.value);
            if (formatted_datetime.isValid)
              return formatted_datetime.toFormat('yyyy-MM-dd hh:mm:ss');
            else
              return "";
          },
          wrapText: true,                    
          autoHeight: true,
          cellStyle: { "line-height": "1.25rem", "word-break": "normal" },
          cellClass: "py-2",
        },
    ];

    const fetchData = () => {
      fetch(import.meta.env.VITE_WEB_SERVER_HOST + "/user_actions.json?q[created_at_gt]=" + startDate.value)
            .then((result) => result.json())
            .then((remoteRowData) => (rowData.value = remoteRowData));      
    }

    // Example load data from sever
    onMounted(() => {
      fetchData();
    });
    
    const onStartDateChange = (date) => {
      fetchData();
    };

    return {
        onGridReady,
        columnDefs,
        rowData,
        defaultColDef,
        clients,
        selectedClient,
        startDate,
        onStartDateChange,
    }

  },
};
</script>