<template>
  <div class="flex flex-col h-screen">
    <main class="flex-1 bg-gray-100">
      <div class="container mx-auto p-4">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 lg:w-1/3 p-2">
            <Combobox as="div" v-model="selectedVehicle">
              <ComboboxLabel class="block text-sm font-medium text-gray-700">Vehicle</ComboboxLabel>
              <div class="relative mt-1">
                <ComboboxInput
                  class="mt-1 block w-full py-2 pl-3 pr-10 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-base"
                  @change="query = $event.target.value" :display-value="(person) => person?.name"
                  @click="($event) => { if ($event.relatedTarget?.id?.includes('headlessui-combobox-button')) return; else !open && $event.target.nextSibling.click(); }"
                  />
                  <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="vehicles.length > 0"
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ComboboxOption v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle" as="template"
                    v-slot="{ active, selected }">
                    <li
                      :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-blue-500 text-white' : 'text-gray-900']">
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ vehicle.name }}
                      </span>

                      <span v-if="selected"
                        :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-blue-500']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>

          <div class="w-full md:w-1/2 lg:w-1/3 p-2">
            <Combobox as="div" v-model="selectedTimeline">
              <ComboboxLabel class="block text-sm font-medium text-gray-700">Timeline</ComboboxLabel>
              <div class="relative mt-1">
                <ComboboxInput
                  class="mt-1 block w-full py-2 pl-3 pr-10 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-base"
                  @change="query = $event.target.value" :display-value="(person) => person?.name" 
                  @click="($event) => { if ($event.relatedTarget?.id?.includes('headlessui-combobox-button')) return; else !open && $event.target.nextSibling.click(); }"
                  />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="timelines.length > 0"
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md">
                  <ComboboxOption v-for="timeline in timelines" :key="timeline.id" :value="timeline" as="template"
                    v-slot="{ active, selected }">
                    <li
                      :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-blue-500 text-white' : 'text-gray-900']">
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ timeline.name }}
                      </span>

                      <span v-if="selected"
                        :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-blue-500']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>

          <div class="w-full md:w-1/2 lg:w-1/6 p-2">
            <label for="start-date" class="block text-sm font-medium text-gray-700">Start Date</label>
            <input type="date" id="start-date"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-base" />
          </div>

          <div class="w-full md:w-1/2 lg:w-1/6 p-2">
            <label for="end-date" class="block text-sm font-medium text-gray-700">End Date</label>
            <input type="date" id="end-date"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-base" />
          </div>
        </div>

        <div class="mt-8">
          <!-- Add your content area here -->
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'

const vehicles = [
  { id: 1, name: 'Vehicle 1' },
  { id: 2, name: 'Vehicle 2' },
  { id: 3, name: 'Vehicle 3' },
  { id: 4, name: 'Vehicle 4' },
  { id: 5, name: 'Vehicle 5' },
]

const timelines = [
  { id: 1, name: 'Timeline 1' },
  { id: 2, name: 'Timeline 2' },
  { id: 3, name: 'Timeline 3' },
  { id: 4, name: 'Timeline 4' },
  { id: 5, name: 'Timeline 5' },
]

const selectedVehicle = ref(vehicles[0])
const selectedTimeline = ref(timelines[0])

</script>